var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('div',{staticClass:"icon-bar"},[_c('a',{style:({
        display: _vm.ChangingDisplay,
      }),attrs:{"href":"#"}},[_c('v-icon',{attrs:{"color":_vm.ChangingColor}},[_vm._v("mdi-facebook")])],1),_c('a',{style:({
        display: _vm.ChangingDisplay,
      }),attrs:{"href":"#"}},[_c('v-icon',{attrs:{"color":_vm.ChangingColor}},[_vm._v("mdi-instagram")])],1),_c('a',{style:({
        display: _vm.ChangingDisplay,
      }),attrs:{"href":"#"}},[_c('v-icon',{attrs:{"color":_vm.ChangingColor}},[_vm._v("mdi-google")])],1),_c('a',{style:({
        display: _vm.ChangingDisplay,
      }),attrs:{"href":"#"}},[_c('v-icon',{attrs:{"color":_vm.ChangingColor}},[_vm._v("mdi-linkedin")])],1),_c('a',{style:({
        display: _vm.ChangingDisplay,
      }),attrs:{"href":"#"}},[_c('v-icon',{attrs:{"color":_vm.ChangingColor}},[_vm._v("mdi-youtube")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }