import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Blog',
    name: 'Blog',
    /* Lazy-loaded to reduce intial load time */
    component: () => import(/* webpackChunkName: "Blog" */ '../views/Blog.vue')

  },
  {
    path: '/Blog/:slug',
    name: 'Blog-post',
    /* Lazy-loaded to reduce intial load time */
    component: () => import(/* webpackChunkName: "Blog-post" */ '../components/BlogPost.vue')

  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   /* Lazy-loaded to reduce intial load time */
  //   component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
  // },
  {
    path: '/Topografia',
    name: 'Topografia',
    /* Lazy-loaded to reduce intial load time */
    component: () => import(/* webpackChunkName: "About" */ '../views/Topografia.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach(() =>{
  /* 
  Changes the document title, 
  according to the path that the client visits 
  */
  var name = window.location.pathname.split("/")[1];
  if(name.length <= 0){
    window.document.title = 'Home - Soluciones Integrales ESTO';
  }else{
    window.document.title = name + ' - Soluciones Integrales ESTO';
  }
  console.log(window.document.title);
})

export default router
