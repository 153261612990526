<template>
  <v-card>
    <div class="icon-bar">
      <a
        href="#"
        v-bind:style="{
          display: ChangingDisplay,
        }"
        ><v-icon :color="ChangingColor">mdi-facebook</v-icon></a
      >
      <a
        href="#"
        v-bind:style="{
          display: ChangingDisplay,
        }"
        ><v-icon :color="ChangingColor">mdi-instagram</v-icon></a
      >
      <a
        href="#"
        v-bind:style="{
          display: ChangingDisplay,
        }"
        ><v-icon :color="ChangingColor">mdi-google</v-icon></a
      >
      <a
        href="#"
        v-bind:style="{
          display: ChangingDisplay,
        }"
        ><v-icon :color="ChangingColor">mdi-linkedin</v-icon></a
      >
      <a
        href="#"
        v-bind:style="{
          display: ChangingDisplay,
        }"
        ><v-icon :color="ChangingColor">mdi-youtube</v-icon></a
      >
    </div>
  </v-card>
</template>
<script>
import "../assets/style/style.css";
export default {
  created() {
    /* Adds event lisener for scroll */
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    /* Removes event lisener for scroll */
    window.removeEventListener("scroll", this.handleScroll);
  },
  data: () => ({
    ChangingColor: "blue darken-1",
    ChangingColorBar: "#000",
    ChangingDisplay: "",
  }),
  methods: {
    handleScroll() {
      /*
      Tracks the position Y 
      position of the scroll and changes 
      the display from block to none 
      after the scroll hits 220 and 
      from none to block bellow 220, 
      in the home screen only
      */
      if (
        window.scrollY > 220 &&
        window.location.pathname.split("/")[1] === ""
      ) {
        // this.ChangingColor = "transparent";
        // this.ChangingColorBar = "transparent";
        this.ChangingDisplay = "none";
      } else {
        // this.ChangingColor = "blue darken-1";
        // this.ChangingColorBar = "#000";
        this.ChangingDisplay = "block";
      }
    },
  },
};
</script>
