<template>
  <v-card class="overflow-hidden">
    <v-app-bar app :color="color" style="height:80px">
      <div class="nav-container">
        <!-- Logo -->
        <img
          class="nav-logo"
          @click="
            path = '/';
            navigation();
          "
          lazy-src="../assets/images/logo_esto-completo.png"
          src="../assets/images/logo_esto-completo.png"
        />
        <!-- Menu on display size higher than 1060px -->
        <div class="nav-menu" id="list-menu">
          <v-menu open-on-hover down offset-y v-for="link in links" :key="link">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                v-bind:style="{ fontSize: fontSize + 'px' }"
                text
                :to="{ path: '/' + link[1] }"
                replace
              >
                {{ link[0] }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <!-- Button an dialog on display size higher than 1060px -->
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              block
              large
              color="#F88930"
              elevation="24"
              rounded
              class="nav-button"
              id="list-menu"
              v-bind="attrs"
              v-on="on"
            >
              ¡Calcula tu proyecto!
            </v-btn>
          </template>
          <ContactForm />
        </v-dialog>
        <!-- Menu on display size lower than 1060px  -->
        <div class="text-center" id="hamburger-menu">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#000" dark v-bind="attrs" v-on="on">
                <i class="nav-icon-menu" aria-hidden="true"
                  ><v-icon>mdi-menu</v-icon></i
                >
              </v-btn>
            </template>
            <v-list class="text-center">
              <v-list-item
                v-for="(link, index) in links"
                :key="index"
                :to="{ path: '/' + link[1] }"
              >
                <v-list-item-title>{{ link[0] }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
  </v-card>
</template>
<script>
import ContactForm from "../components/ContactForm.vue";
export default {
  created() {
    /* Adds event lisener for scroll */
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    /* Removes event lisener for scroll */
    window.removeEventListener("scroll", this.handleScroll);
  },
  name: "Navbar",

  components: {
    ContactForm,
    //
  },
  data: () => ({
    sizeY: "",
    color: "transparent",
    dialog: false,
    fontSize: 13,
    path: "",
    links: [
      ["Topografía", "Topografia"],
      ["Escanér 3D", "Escaner-3D"],
      ["Arquitectura", "Servicios-para-Arquitectura"],
      ["Fotogrametría", "Fotogrametria"],
      ["Gerencia de proyectos", "Gerencia-de-proyectos"],
      ["Blog", "Blog"],
    ],
    items: [
      { title: "Servicio 1" },
      { title: "Servicio 2" },
      { title: "Servicio 3" },
      { title: "Servicio 4" },
    ],
  }),
  methods: {
    navigation() {
      /* Changes the path dynamically */
      window.location = this.path;
    },
    handleScroll() {
      /*
      Tracks the position Y 
      position of the scroll and changes 
      the color from transparent to default 
      grey after the scroll hits 50 and 
      from default to transparent bellow 50
      */
      if (window.scrollY > 50) {
        this.color = "";
      }else{
        this.color = "transparent";
      }
    },
  },
};
</script>
