<template>
  <v-card height="100%">
    <v-container>
      <div class="contact-info-container">
        <div class="contact-info-title" style="font-size:30px; color:white">
          - Información de Contacto -
        </div>
        <div class="contact-info-telefono mt-n4">
          <div style="font-size:25px; color:white">- Teléfono -</div>
          <div>
            <v-icon>mdi-phone</v-icon> <a href="tel:3325384580" style="color:white">33 2538 4580</a>
          </div>
          <div>
            <v-icon>mdi-phone</v-icon> <a href="tel:3335701412" style="color:white">33 3570 1412</a>
          </div>
          <div>
            <v-icon>mdi-phone</v-icon> <a href="tel:3333708520" style="color:white">33 3370 8520</a>
          </div>
        </div>
        <div class="contact-info-email">
          <div style="font-size:25px; color:white">- Correo -</div>
          <div><v-icon>mdi-email</v-icon> <a href="mailto:info@estosi.com" style="color:white"> info@estosi.com</a></div>
        </div>
        <div class="contact-info-social" style="font-size:25px; color:white">
          - Redes Sociales -
          <div>
            <a href="#"
              ><v-icon class="social" style="color:#F88930"
                >mdi-facebook</v-icon
              ></a
            >
            <a href="#"
              ><v-icon class="social" style="color:#F88930"
                >mdi-instagram</v-icon
              ></a
            >
            <a href="#"
              ><v-icon class="social" style="color:#F88930"
                >mdi-google</v-icon
              ></a
            >
            <a href="#"
              ><v-icon class="social" style="color:#F88930"
                >mdi-linkedin</v-icon
              ></a
            >
            <a href="#"
              ><v-icon class="social" style="color:#F88930"
                >mdi-youtube</v-icon
              ></a
            >
          </div>
        </div>
      </div>
    </v-container>
  </v-card>
</template>
<script>
export default {
  data: () => ({}),
  methods: {},
};
</script>
