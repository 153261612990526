<template>
  <div class="carousel-container">
    <v-carousel
      class="carousel"
      height="300px"
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      hide-delimiters
    >
      <v-carousel-item
        v-for="(Review, i) in Object.values(Reviews[0])"
        :key="i"
      >
        <v-row class="fill-height" align="center" justify="center">
          <v-card class="elevation-16 mx-6 py-3" :color="colors[i]">
            <v-card-text class="white--text mt-8 headline text-center">
              <v-avatar size="56">
                <img alt="user" :src="Object.values(Reviews[0])[i].Url" />
              </v-avatar>
              <p class="mt-1">
                {{ Object.values(Reviews[0])[i].Name }}
              </p>
              <p class="font-weight-thin text--secondary carousel-card-subtext">
                {{ Object.values(Reviews[0])[i].Work }}
              </p>
            </v-card-text>
            <v-card-text class="card-text-carousel mt-n9 mx-1">
              {{ Object.values(Reviews[0])[i].Review }}
              <div class="text-center mt-8">
                <v-rating
                  v-model="Object.values(Reviews[0])[i].Rate"
                  color="yellow darken-3"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  half-increments
                  large
                  readonly
                ></v-rating>
              </div>
            </v-card-text>
          </v-card>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
import "../assets/style/style.css";
export default {
  name: "Home",
  components: {},
  data: () => ({
    colors: ["", "#3D76AB", "", "#f88930", ""],
    Reviews: [
      {
        First: {
          Name: "John",
          Work: "3D Model",
          Url:
            "https://cdn.pixabay.com/photo/2020/06/24/19/12/cabbage-5337431_1280.jpg",
          Rate: 5,
          Review:
            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis distinctio illo eligendi repudiandae autem temporibus illum, cupiditate placeat enim. Rerum distinctio sit consequatur totam veniam tempore vitae facilis architecto optio. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis distinctio illo eligendi repudiandae autem temporibus illum, cupiditate placeat enim. Rerum distinctio sit consequatur totam veniam tempore vitae facilis architecto optio. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis distinctio illo eligendi repudiandae autem temporibus illum, cupiditate placeat enim. Rerum distinctio sit consequatur totam veniam tempore vitae facilis architecto optio. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis distinctio illo eligendi repudiandae autem temporibus illum, cupiditate placeat enim. Rerum distinctio sit consequatur totam veniam tempore vitae facilis architecto optio.",
        },
        Second: {
          Name: "Karen",
          Work: "AsBuilt",
          Url:
            "https://cdn.pixabay.com/photo/2020/07/12/07/47/bee-5396362_1280.jpg",
          Rate: 4,
          Review:
            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis distinctio illo eligendi repudiandae autem temporibus illum, cupiditate placeat enim. Rerum distinctio sit consequatur totam veniam tempore vitae facilis architecto optio.",
        },
        Third: {
          Name: "Ryan",
          Work: "Escaner laser",
          Url: "https://cdn.vuetifyjs.com/images/cards/sunshine.jpg",
          Rate: 4.5,
          Review:
            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis distinctio illo eligendi repudiandae autem temporibus illum, cupiditate placeat enim. Rerum distinctio sit consequatur totam veniam tempore vitae facilis architecto optio.",
        },
        Fourth: {
          Name: "Maria",
          Work: "Topografia",
          Url: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
          Rate: 5,
          Review:
            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis distinctio illo eligendi repudiandae autem temporibus illum, cupiditate placeat enim. Rerum distinctio sit consequatur totam veniam tempore vitae facilis architecto optio.",
        },
        Fifth: {
          Name: "Erik",
          Work: "Supervision de obra",
          Url: "https://cdn.vuetifyjs.com/images/cards/road.jpg",
          Rate: 5,
          Review:
            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quis distinctio illo eligendi repudiandae autem temporibus illum, cupiditate placeat enim. Rerum distinctio sit consequatur totam veniam tempore vitae facilis architecto optio.",
        },
      },
    ],
  }),
  methods: {},
  mounted() {},
};
</script>
