<template>
  <v-card height="100%">
    <v-container>
      <div class="contact-form-container">
        <div class="contact-form-title" style="font-size:30px; color:white">
          - Contáctanos -
        </div>
        <v-text-field
          class="contact-form-name"
          v-model="name"
          :error-messages="nameErrors"
          label="Nombre*"
          required
          prepend-icon="mdi-account"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        ></v-text-field>
        <v-text-field
          class="contact-form-email"
          v-model="email"
          :error-messages="emailErrors"
          label="E-mail*"
          required
          prepend-icon="mdi-email"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        ></v-text-field>
        <v-text-field
          class="contact-form-ubicacion"
          v-model="ubicacion"
          :error-messages="ubicacionErrors"
          label="Ubicación*"
          required
          prepend-icon="mdi-map-marker"
          hint="Ubicación del trabajo*"
          @input="$v.ubicacion.$touch()"
          @blur="$v.ubicacion.$touch()"
        ></v-text-field>
        <v-text-field
          class="contact-form-size"
          v-model="size"
          :error-messages="sizeErrors"
          label="Metros cuadrados*"
          required
          prepend-icon="mdi-cube-scan"
          @input="$v.size.$touch()"
          @blur="$v.size.$touch()"
        ></v-text-field>
        <v-select
          class="contact-form-deliver"
          v-model="select"
          :items="items"
          :error-messages="selectErrors"
          label="Entregable*"
          required
          prepend-icon="mdi-package-variant-closed"
          @change="$v.select.$touch()"
          @blur="$v.select.$touch()"
        ></v-select>
        <v-text-field
          class="contact-form-msg"
          v-model="msg"
          :error-messages="msgErrors"
          label="Mensaje"
          hint="Algo que desees comentarnos"
          :required="req"
          prepend-icon="mdi-message"
          @change="$v.msg.$touch()"
          @blur="$v.msg.$touch()"
        ></v-text-field>
        <div class="contact-form-check">
          <small>*Indica los campos requeridos</small>
          <v-checkbox
            v-model="checkbox"
            :error-messages="checkboxErrors"
            label="Acepto términos y condiciones"
            required
            @change="$v.checkbox.$touch()"
            @blur="$v.checkbox.$touch()"
          ></v-checkbox>
        </div>
        <div class="contact-form-btn">
          <v-btn color="#F88930" text @click="clear">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit">
            Enviar
          </v-btn>
        </div>
      </div>
    </v-container>
  </v-card>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
/* 
Sets the type and require validation 
for the componets of the form 
*/
  validations: {
    name: { required },
    email: { required, email },
    select: { required },
    ubicacion: { required },
    size: { required },
    msg: { required },
    checkbox: {
      checked(val) {
        return val;
      },
    },
  },

  data: () => ({
    name: "",
    email: "",
    ubicacion: "",
    size: "",
    msg: "",
    select: null,
    items: [
      "Renta de cuadrillas",
      "Escaneo 3D",
      "Nube de Puntos",
      "Planos/ Modelo Asbuilt",
      "Levantamineto de Terreno",
      "Levantamiento GPS/GNSS",
      "Levantamineto de Infrestructura",
      "Inventario Industrial",
      "Recorrido Virtual",
      "Otro (especificar en mensaje)*",
    ],
    checkbox: false,
    // req: "",
  }),
  watch: {},
  computed: {
    req: function() {
      /* 
      Returns the value of req to true, 
      if the option "Otro (especificar en mensaje)* is selected,
      in order for the client to specify the service on the msg"
      */
      if (this.select == "Otro (especificar en mensaje)*") {
        return true;
      } else {
        return false;
      }
    },
    /* Validates each of the contact form inputs */
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked &&
        errors.push("Debe aceptar los términos y condiciones para continuar");
      return errors;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.select.$dirty) return errors;
      !this.$v.select.required && errors.push("Seleccione un servicio.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Se requiere su nombre.");
      return errors;
    },
    sizeErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required &&
        errors.push("Se requieren los metros cuadrados.");
      return errors;
    },
    msgErrors() {
      const errors = [];
      if (!this.$v.name.$dirty && this.req) return errors;
      !this.$v.name.required &&
        this.req &&
        errors.push("Se requiere especificar el servicio");
      return errors;
    },
    ubicacionErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Se requiere la ubicacion.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Debe ingresar un e-mail valido.");
      !this.$v.email.required && errors.push("Se requiere un e-mail.");
      return errors;
    },
    /* End of the validations */
  },

  methods: {
    submit() {
      /* Calls the validation and send the form info */
      this.$v.$touch();
    },
    clear() {
      /* Clears and resets the validation form info */
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.size = "";
      this.ubicacion = "";
      this.msg = "";
      this.req = false;
      this.select = null;
      this.checkbox = false;
    },
  },
};
</script>
