<template>
  <v-container fluid>
    <!-- hexagon menu -->
    <div class="background-img-hex">
      <div class="hexagon-menu">
        <ul id="hexGrid">
          <li class=""></li>
          <li class=""></li>
          <li class=""></li>
          <li class="hex" v-tilt="{ speed: 300, perspective: 700 }">
            <div class="hexIn">
              <a class="hexLink" href="/Topografia">
                <img src="../assets/images/topografia_icon.png" alt="" />
                <h1>Topografía</h1>
                <p>Contamos con renta de cuadrillas de topografía.</p>
              </a>
            </div>
          </li>
          <li class="hex" v-tilt="{ speed: 300, perspective: 700 }">
            <div class="hexIn">
              <a class="hexLink" href="/Escaner-3D">
                <img src="../assets/images/escaner-icon.png" alt="" />
                <h1>Escanér 3D</h1>
                <p>
                  El escaneo 3D, hace un levantamiento de cualquier área
                  posible.
                </p>
              </a>
            </div>
          </li>
          <li class="hex" v-tilt="{ speed: 300, perspective: 700 }">
            <div class="hexIn">
              <a class="hexLink" href="/Fotogrametria">
                <img src="../assets/images/dron-icon.png" alt="" />
                <h1>Fotogrametría</h1>
                <p>
                  Mediciones por medio de fotografías para, obtener datos
                  topograficos.
                </p>
              </a>
            </div>
          </li>
          <li class="hex" v-tilt="{ speed: 300, perspective: 700 }">
            <div class="hexIn">
              <a class="hexLink" href="/">
                <img
                  src="../assets/images/logo-esto.png"
                  alt=""
                  class=""
                  style="scale:0.65;"
                />
                <h1>Soluciones Integrales ESTO</h1>
                <p>Más de 30 años de experiencia nos respaldan.</p>
              </a>
            </div>
          </li>
          <li class="hex" v-tilt="{ speed: 300, perspective: 700 }">
            <div class="hexIn">
              <a class="hexLink" href="/Servicios-para-Arquitectura">
                <img src="../assets/images/arquitectura_icon.png" alt="" />
                <h1>Servicios para Arquitectura</h1>
                <p>
                  Un recorrido virtual, donde puedas tomar mediciones de
                  cualquier elemento.
                </p>
              </a>
            </div>
          </li>
          <li class="hex" v-tilt="{ speed: 300, perspective: 700 }">
            <div class="hexIn">
              <a class="hexLink" href="/Gerencia-de-proyectos">
                <img src="../assets/images/gerencia_icon.png" alt="" />
                <h1>Gerencia de proyectos</h1>
                <p>
                  Gestionamos sus proyectos desde su concepción hasta su
                  construcción y puesta en marcha.
                </p>
              </a>
            </div>
          </li>
          <li class="hex" v-tilt="{ speed: 300, perspective: 700 }">
            <div class="hexIn">
              <a class="hexLink" href="/Blog">
                <img src="../assets/images/blog-icon.png" alt="" />
                <h1>Blog</h1>
                <p>
                  Un lugar donde puedes encontrar publicaciones sobre ESTOSI.
                </p>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- graph card container -->
    <div class="graph-container">
      <div class="row">
        <div class="column">
          <div class="card" style="background-color: #3D76AB;">
            <!-- <p><i class="fa fa-map fa-3x"></i></p> -->
            <p><v-icon style="font-size: 64px;">mdi-map-outline</v-icon></p>
            <h3>+1,956,540 m²</h3>
            <p>Medidos</p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <!-- <p><i class="fa fa-cloud fa-3x"></i></p> -->
            <p><v-icon style="font-size: 64px;">mdi-cloud</v-icon></p>

            <h3>+1,544,540 m²</h3>
            <p>Nubes de puntos</p>
          </div>
        </div>
        <div class="column">
          <div class="card" style="background-color:#f88930">
            <!-- <p><i class="fa fa-pencil fa-3x"></i></p> -->
            <p><v-icon style="font-size: 64px;">mdi-pencil</v-icon></p>
            <h3>+1,320,000 m²</h3>
            <p>Dibujados</p>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <!-- <p><i class="fa fa-cube fa-3x"></i></p> -->
            <p><v-icon style="font-size: 64px;">mdi-cube-scan</v-icon></p>
            <h3>+648,040 m²</h3>
            <p>Modelados</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Company info -->
    <div class="graph-container-2">
      <v-card>
        <div class="row">
          <div class="column-4">
            <div class="card-2" style="background-color:transparent">
              <p>
                <v-icon style="font-size: 64px;">mdi-account-hard-hat</v-icon>
              </p>
              <h3>- Profesionales -</h3>
              <p style="color: #999">
                Contamos con un equipo de profesionales a su servicio,
                capacitados constantemente para mantenernos siempre a la
                vanguardia. Nuestro equipo técnico se conforma de: Ingenieros
                Topógrafos, Arquitectos, Ingenieros Civiles, Ingenieros
                Industriales
              </p>
            </div>
          </div>
          <div class="column-4">
            <div class="card-2" style="background-color:transparent">
              <p>
                <v-icon style="font-size: 64px;">mdi-chart-multiple</v-icon>
              </p>
              <h3>- Eficientes -</h3>
              <p style="color: #999">
                Contamos con gran variedad de equipos desde un Distanciometro de
                mano, hasta Escáner Láser, lo cual nos permite decidir la mejor
                opción para optimizar tiempos y siempre buscar la mayor
                precisión en tus proyectos.
              </p>
            </div>
          </div>
          <div class="column-4">
            <div class="card-2" style="background-color:transparent">
              <p>
                <v-icon style="font-size: 64px;">mdi-star-check-outline</v-icon>
              </p>
              <h3>- Garantía -</h3>
              <p style="color: #999">
                Gracias a nuestra amplia experiencia de más de 25 años,
                tecnología de vanguardia y manuales de procedimientos podemos
                ofrecerte garantía en todas nuestras mediciones, no tendrás que
                realizar retrabajos. Y si existe algún faltante de información
                lo realizamos sin costo.
              </p>
            </div>
          </div>
          <div class="column-4">
            <div class="card-2" style="background-color:transparent">
              <p><v-icon style="font-size: 64px;">mdi-podium-gold</v-icon></p>
              <h3>- Experiencia -</h3>
              <p style="color: #999">
                Contamos con más de 25 años de experiencia en el mercado de la
                topografía y Geomática, siempre adaptándonos a las nuevas
                tecnologías, sin dejar de lado la precisión y correcciones
                necesarias para un buen resultado.
              </p>
            </div>
          </div>
          <div class="column-4">
            <div class="card-2" style="background-color:transparent">
              <p>
                <v-icon style="font-size: 64px;">mdi-gesture-tap-hold</v-icon>
              </p>
              <h3>- Tecnología -</h3>
              <p style="color: #999">
                Invertimos en la mejor tecnología del mercado para poder ofrecer
                más y mejores servicios.
              </p>
            </div>
          </div>
          <div class="column-4">
            <div class="card-2" style="background-color:transparent">
              <p>
                <v-icon style="font-size: 64px;"
                  >mdi-package-variant-closed</v-icon
                >
              </p>
              <h3>- Entregables -</h3>
              <p style="color: #999">
                Contamos con un alto estándar de entregables, sea planos, nubes,
                modelos. Pero nos adaptamos a las necesidades específicas de
                nuestros clientes, pudiendo adaptar nuestra entrega desde lo más
                básico hasta lo más complejo. Incluso basándonos en estándares o
                manuales de ustedes nuestros clientes.
              </p>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <!-- review carousel -->
    <ReviewCarousel />
    <!-- contact us form -->
    <div class="graph-container">
      <div class="row">
        <div class="column-2">
          <ContactInfo />
        </div>
        <div class="column-3">
          <ContactForm />
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import ReviewCarousel from "../components/ReviewCarousel.vue";
import ContactForm from "../components/ContactForm.vue";
import ContactInfo from "../components/ContactInfo.vue";
import "../assets/style/style.css";
import "../assets/style/hexagons.css";
export default {
  name: "Home",
  components: {
    ContactForm,
    ContactInfo,
    ReviewCarousel,
  },
  data: () => ({
    path: "",
  }),
  methods: {
    // navigation() {
    //   window.location = this.path;
    // },
    // test() {
    //   console.log(Object.values(this.Reviews[0])[0].Name);
    // },
  },
  mounted() {
    // this.test();
  },
};
</script>
