<template>
  <v-app id="app">
    <!-- Navbar -->
    <Navbar />
    <!-- Social media icon bar -->
    <SocialMediaBar/>
    <v-main>
      <!-- Routes to all of the views -->
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <!-- Footer -->
    <v-footer app dark padless mt-5>
      <v-card class="flex" flat color="#395B9A">
        <v-card-text class="py-2 text-center">
          {{ new Date().getFullYear() }} —
          <strong style="color:#F18900">&copy; ESTOSI</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import "./assets/style/style.css";
import Navbar from "./components/Navbar.vue";
import SocialMediaBar from './components/SocialMediaBar.vue'
export default {
  name: "App",

  components: {
    Navbar,
    SocialMediaBar,
  },

  data: () => ({
    //
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
  methods: {
    //
  },
};
</script>
