var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-hidden"},[_c('v-app-bar',{staticStyle:{"height":"80px"},attrs:{"app":"","color":_vm.color}},[_c('div',{staticClass:"nav-container"},[_c('img',{staticClass:"nav-logo",attrs:{"lazy-src":"../assets/images/logo_esto-completo.png","src":require("../assets/images/logo_esto-completo.png")},on:{"click":function($event){_vm.path = '/';
          _vm.navigation();}}}),_c('div',{staticClass:"nav-menu",attrs:{"id":"list-menu"}},_vm._l((_vm.links),function(link){return _c('v-menu',{key:link,attrs:{"open-on-hover":"","down":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({ fontSize: _vm.fontSize + 'px' }),attrs:{"text":"","to":{ path: '/' + link[1] },"replace":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(link[0])+" ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)}),1),_c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"nav-button",attrs:{"block":"","large":"","color":"#F88930","elevation":"24","rounded":"","id":"list-menu"}},'v-btn',attrs,false),on),[_vm._v(" ¡Calcula tu proyecto! ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ContactForm')],1),_c('div',{staticClass:"text-center",attrs:{"id":"hamburger-menu"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#000","dark":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"nav-icon-menu",attrs:{"aria-hidden":"true"}},[_c('v-icon',[_vm._v("mdi-menu")])],1)])]}}])},[_c('v-list',{staticClass:"text-center"},_vm._l((_vm.links),function(link,index){return _c('v-list-item',{key:index,attrs:{"to":{ path: '/' + link[1] }}},[_c('v-list-item-title',[_vm._v(_vm._s(link[0]))])],1)}),1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }